<template>
  <div>
    <div class="subHeader base noFlex">
      <div class="module-header">
        <h2>결제 관리</h2>
      </div>

      <div class="wtBox">
        <div class="module-filter">
          <div class="module-button">
            <select v-model="inputMode">
              <option value="" disabled>선택</option>
              <option value="merchant_uid">주문번호</option>
              <option value="buyer_name">구매자</option>
              <option value="buyer_email">이메일</option>
            </select>
          </div>
          <div class="box">
            <div style="width: 80%">
              <Datepicker
                v-model="startYmd"
                :language="ko"
                :format="customFormatter"
                placeholder="시작일"
              ></Datepicker>
            </div>
            <i class="material-icons">calendar_today</i>
          </div>
          <div class="box">
            <div style="width: 80%">
              <Datepicker
                v-model="endYmd"
                :language="ko"
                :format="customFormatter"
                placeholder="종료일"
              ></Datepicker>
            </div>
            <i class="material-icons">calendar_today</i>
          </div>
          <div class="search">
            <fieldset>
              <legend>검색</legend>
              <input
                type="search"
                placeholder="검색어를 입력하세요"
                v-model="searchWord"
                @keyup.enter="search"
              />
              <button type="submit" class="material-icons" @click="search">
                search
              </button>
            </fieldset>
          </div>
          <button type="button" class="icoTxt" @click="reload">
            <i class="material-icons">replay</i>
            <b>새로고침</b>
          </button>
        </div>
      </div>

      <div class="module-config">
        <div class="wtBox config-list">
          <header class="flexL">
            <h3>결제내역 리스트</h3>
            <span class="rubberBand"></span>
            <div class="funcIcons">
              <button type="button" class="material-icons ico">print</button>
            </div>
          </header>
          <table class="module-table lineRow">
            <tr>
              <th scope="col">번호</th>
              <th scope="col">상품주문 번호</th>
              <th scope="col">결제날짜</th>
              <th scope="col">결제금액</th>
              <th scope="col">충전포인트</th>
              <th scope="col">주문명</th>
              <th scope="col">구매자</th>
              <th scope="col">이메일</th>
              <th scope="col">카드명</th>
              <th scope="col">상태</th>
            </tr>
            <tr v-for="(item, index) in paymentList" :key="index">
              <td>{{ item.id }}</td>
              <td>
                <router-link
                  class="primary"
                  :to="{
                    name: 'adminPaymentDetail',
                    params: { checkoutId: item._id },
                  }"
                  >{{ item.merchant_uid }}</router-link
                >
              </td>
              <td>{{ moment(item.created_at).format("YY.MM.DD HH:mm") }}</td>
              <td>{{ comma(item.paid_amount) }} 원</td>
              <td>{{ item.total_point }} P</td>
              <td>
                {{ item.serviceType }}
              </td>
              <td>
                {{ item.buyer_name }}
              </td>
              <td>{{ item.buyer_email }}</td>
              <td>
                {{ item.card_name }}
              </td>
              <td
                :class="{
                  success: item.status == 'paid',
                  error: item.status == 'paid_fail',
                  primary: item.status == 'refund',
                  orange: item.status == 'cancelled',
                  error: item.status == 'norefund',
                }"
              >
                {{
                  item.status == "paid"
                    ? "결제 완료"
                    : item.status == "paid_fail"
                    ? "결제 실패"
                    : item.status == "refund"
                    ? "환불 신청"
                    : item.status == "cancelled"
                    ? "환불 완료"
                    : item.status == "norefund"
                    ? "환불 거절"
                    : ""
                }}
                <!-- 결제완료 : success, 환불신청: primary, 환불완료 : orange, 환불불가 : error,  -->
              </td>
            </tr>
          </table>
        </div>
        <div class="pagination" v-if="total > 0">
          <el-pagination
            layout="prev, pager, next"
            :total="total"
            :page-size="10"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </div>
    <transition name="fade">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import { 
  makingOrder,
  deleteUser,
  checkPaymentsByStatus, 
  createRefund
} from "@/api/index";
import moment from "moment";
import { mapState } from "vuex";
import { eventBus } from "@/main";
import { setNavbar } from "@/mixins/setNavbar";
import { format } from "@/mixins/format";
import Datepicker from "vuejs-datepicker";
import { ko } from "vuejs-datepicker/dist/locale";
export default {
  components: { Datepicker },
  mixins: [setNavbar, format],
  data() {
    return {
      isEdit: false,
      ko: ko,
      moment: moment,
      display: true,
      confirmTitle: "",
      message1: "",
      message2: "",
      currentComponent: null,
      displayConfirm: false,
      paymentList: [],
      dateKeyword: "",
      searchWord: "",
      currentPage: 1,
      total: 0,
      previous: null,
      next: null,
      startYmd: "",
      endYmd: "",
      inputMode: "",
    };
  },
  mounted() {
    eventBus.$on("closeConfirm", () => {
      this.closeConfirm();
    });
    this.getPaymentByStatus();
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    viewComponent(view) {
      this.currentComponent = view;
    },
    closeConfirm() {
      this.displayConfirm = false;
    },
    closeModal() {
      eventBus.$emit("closeModal", true);
    },
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getPaymentByStatus();
    },
    handleClose() {
      setTimeout(() => {
        eventBus.$emit("closeModal", true);
      }, 2000);
    },
    dateFormat(inputDate) {
      let date = new Date(inputDate);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }

      return year + "" + month + "" + day;
    },
    confirm() {
      this.closeModal();
    },
    handleEditMode() {
      this.isEdit = !this.isEdit;
    },
    handleStatus() {
      this.getPaymentByStatus();
    },

    handlePaging(page) {
      if (page > this.total) return false;
      else if (page < 1) return false;
      this.currentPage = page;
      this.getPaymentByStatus();
    },
    handleMenu(id) {
      this.$router.push({ name: "paymentDetail", query: { imp_uid: id } });
    },
    deleteMenu(id) {
      deleteUser(id).then((res) => {
        if (res.status != 200) {
          return alert(res.data.message);
        }
      });
    },
    doOrder(merchant_uid) {
      makingOrder(merchant_uid).then((res) => {
        if (res.status == 200) {
          this.getPaymentByStatus();
          alert(res.data.message);
        } else {
          alert(res.data.message);
        }
      });
    },
    reload() {
      this.currentPage = 1;
      this.total = 0;
      this.searchWord = "";
      this.payementLis = [];
      this.inputMode = "";
      this.startYmd = "";
      this.endYmd = "";
      this.getPaymentByStatus();
    },
    search() {
      this.getPaymentByStatus();
    },
    confirmRefund(id) {
      if (confirm("주문번호 : " + id + " 환불하시겠습니까?") == true) {
        let data = {
          merchant_uid: id,
        };
        createRefund(data).then((res) => {
          if (res.status == 200) {
            this.getPaymentByStatus();
            alert("환불되었습니다.");
          } else {
            alert(res.data.message);
          }
        });
      }
    },
    getPaymentByStatus() {
      let params = {
        page: this.currentPage,
        searchWord: this.searchWord,
        inputMode: this.inputMode,
        startYmd: this.startYmd,
        endYmd: this.endYmd,
      };
      checkPaymentsByStatus(params).then((res) => {
        if (res.status == 200) {
          this.paymentList = res.data.data;
          this.total = res.data.totalPages;
          this.currentPage = res.data.currentPage;
        } else {
          return alert(res.data.message);
        }
      });
    },
  },
};
</script>
